import React from 'react'
import Helmet from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'

import '../css/global.css'

const Layout = ({children}) => (
    <div>
        <Helmet
            title='Schreiner Löw - Innenausbau, Montagebau in Bad Saulgau'
            meta={[
                {
                    name: 'description',
                    content: 'Schreiner Löw - Innenausbau, Montagebau in Bad Saulgau und Umgebung. Mobil: +49 151 / 64628858, Festnetz: +49 7581 / 5082548'
                },
                {name: 'keywords', content: 'Innenausbau, Montagebau, Schreiner, Bad Saulgau, Hochberg'},
            ]}
        />
        <div className={'sm:min-h-screen flex flex-col content-between'}>
            <Header siteTitle='Schreiner Löw - Innenausbau, Montagebau in Bad Saulgau'/>
            <div className={'flex-1'}>
                {children}
            </div>
            <Footer/>
        </div>
    </div>
)

export default Layout
