import React from 'react'
import Link from 'gatsby-link'
import Icon from './icon'
import BackgroundImage from "gatsby-background-image";
import {graphql, StaticQuery} from "gatsby";

const Footer = () => (
    <StaticQuery
        query={graphql`
                query {
                    heroImage: file(relativePath: { eq: "heinz-freigestellt.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 1900) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `}
        render={(data) => {

            return (
                <footer className="block text-primary-900 ">

                    <BackgroundImage
                        Tag="section"
                        className={'bg-primary-100 bg-center w-full bg-repeat-y bg-cover leading-loose'}
                        fluid={data.heroImage.childImageSharp.fluid}
                        backgroundColor={`#EBF7EB`}
                    >
                        <div className={'sm:bg-primary-100'}>
                            <div className="mx-auto container sm:flex flex-wrap px-2">
                                <div className="sm:w-1/3 h-auto py-8">
                                    <div className="font-semibold">Schreiner Löw</div>
                                    <div>Heinz Löw</div>
                                </div>
                                <div
                                    className="sm:w-1/3 h-auto sm:mt-0 sm:border-l sm:px-4 py-0 sm:py-8 border-primary-200">
                                    <div>Kronenweg 6</div>
                                    <div>88348 Bad Saulgau / Hochberg</div>
                                    <div>Deutschland</div>
                                </div>
                                <div
                                    className="font-semibold sm:w-1/3 h-auto sm:mt-0 mt-8 sm:border-l sm:px-4 pb-8 sm:py-8 border-primary-200">
                                    <a className={'flex justify-start sm:justify-end items-center whitespace-no-wrap hover:underline'}
                                       href="tel:+4915164628858"
                                    ><Icon className={'mr-2'} icon={"mobile"}/>+49 151 / 64628858</a>

                                    <a className={'flex justify-start sm:justify-end items-center whitespace-no-wrap hover:underline'}
                                       href="tel:+4975815082548"
                                    ><Icon className={'mr-2'} icon={"phone"}/>+49 7581 / 5082548</a>

                                    <a href="mailto:heinz@schreiner-loew.de"
                                       className={'mt-4 flex justify-start sm:justify-end items-center whitespace-no-wrap hover:underline'}>
                                        <Icon className={'mr-2 hover:underline'} icon={"envelope"}/>heinz@schreiner-loew.de
                                    </a>
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>

                    <div className={'bg-primary-900 '}>
                        <div className="mx-auto container text-primary-300 text-sm px-6 py-2">
                            <ul className="list-reset leading-normal flex sm:mx-auto">
                                <li className={'ml-auto'}>
                                    <Link to="/impressum" className={'hover:underline mr-4'}>Impressum</Link>
                                </li>
                                <li>
                                    <span className={'mr-4 text-primary-300'}>|</span><Link to="/datenschutz"
                                                                                            className={'hover:underline'}>Datenschutz</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </footer>
            )
        }}/>
);

export default Footer

