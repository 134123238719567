import React from 'react';
import Icon from './icon';
import Logosmall from '../img/logosmall.inline.svg'

export default class Header extends React.Component {
    constructor(props)
    {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle()
    {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render()
    {
        return (
            <div className="bg-primary-100 inset-x-0">
                <div className={'mx-auto container flex h-8 sm:h-16 items-center'}>
                    <div className="w-full mx-auto px-2">
                        <div className="flex items-center">

                            <div className="flex items-center">
                                <a href="/" className="block lg:mr-4 text-green-700">
                                    <Logosmall className={'h-3 sm:h-6 text-gray-600'}/>
                                    <h1 className={'hidden'}>Schreiner Löw</h1>
                                </a>
                                <h2 className={'hidden xl:block ml-8 text-2xl text-primary-800'}>Innenausbau &amp; Montagebau
                                    in Bad Saulgau</h2>
                            </div>

                            <div className="font-semibold flex text-right justify-end flex-grow text-primary-800">
                                <a className={'md:mr-8 whitespace-no-wrap hover:underline hidden sm:flex justify-start items-center'}
                                   href="tel:+4915164628858"
                                ><Icon className={'inline-block mr-2'} icon={"mobile"}/>
                                    <div>+49 151 / 64628858</div>
                                </a>
                                <a className={'whitespace-no-wrap hover:underline hidden md:flex justify-start items-center'}
                                   href="tel:+4975815082548"
                                ><Icon className={'inline-block mr-2'} icon={"phone"}/>
                                    <div>+49 7581 / 5082548</div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

