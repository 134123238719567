import React from 'react';

export default class Icon extends React.Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        switch(this.props.icon)
        {
            case 'mobile':
                return <svg className={'h-4 w-4 fill-current ' + this.props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M192 416c0 17.7-14.3 32-32 32s-32-14.3-32-32 14.3-32 32-32 32 14.3 32 32zM320 48v416c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h224c26.5 0 48 21.5 48 48zm-32 0c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16v416c0 8.8 7.2 16 16 16h224c8.8 0 16-7.2 16-16V48z"/></svg>
            case 'phone':
                return <svg className={'h-4 w-4 fill-current ' + this.props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M487.776 24.051L387.025.806c-14.745-3.405-29.786 4.226-35.749 18.14l-46.5 108.494c-5.452 12.723-1.778 27.73 8.935 36.495l53.854 44.063c-34.011 69.234-90.333 125.557-159.566 159.566l-44.063-53.854c-8.765-10.713-23.774-14.385-36.496-8.935L18.946 351.276C5.031 357.24-2.598 372.275.806 387.026l23.244 100.75C27.342 502.039 39.864 512 54.5 512 306.659 512 512 307.793 512 54.5c0-14.638-9.961-27.159-24.224-30.449zM55.096 480l-22.972-99.557 107.402-46.03 59.53 72.757c103.641-48.624 159.675-104.867 208.113-208.113l-72.758-59.53 46.031-107.402L480 55.096C479.68 289.713 289.638 479.68 55.096 480z"/></svg>;
            case 'envelope':
                return <svg className={'h-4 w-4 fill-current ' + this.props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"/></svg>;
        }
    }
}

Icon.defaultProps = {
    className: ''
}
